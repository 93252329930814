import store from '@/state/store'

export default [
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/pages/account/login'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('../views/pages/account/register'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/forgot-password',
        name: 'Forgot-password',
        component: () => import('../views/pages/account/forgot-password'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/logout',
        name: 'logout',
        meta: {
            authRequired: true,

            beforeResolve(routeTo, routeFrom, next) {

                if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
                    store.dispatch('auth/logOut')
                } else {
                    store.dispatch('authfack/logout')
                }
                const authRequiredOnPreviousRoute = routeFrom.matched.some(
                    (route) => route.push('/login')
                )
                // Navigate back to previous page, or home as a fallback
                next(authRequiredOnPreviousRoute ? { name: 'home' } : { ...routeFrom })
            },
        },
    },

    // ***********  Dashboard Start *********** //
    {
        path: '/',
        name: 'home',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/dashboard/index')
    },
    {
        path: '/dashboard-product-details',
        name: 'DashboardProductDetails',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/dashboard/orderDetails')
    },
    // ***********  Dashboard End  *********** //

    // ***********  Master Data Start  *********** //
    {
        path: '/master-data/brands',
        name: 'Brands',
        meta: { authRequired: true },
        component: () => import('../views/pages/master_data/brands')
    },
    {
        path: '/master-data/menus',
        name: 'Menus',
        meta: { authRequired: true },
        component: () => import('../views/pages/master_data/menus')
    },
    {
        path: '/master-data/sub-menus',
        name: 'subMenus',
        meta: { authRequired: true },
        component: () => import('../views/pages/master_data/subMenus')
    },
    {
        path: '/master-data/list-sub-menu',
        name: 'listSubMenu',
        meta: { authRequired: true },
        component: () => import('../views/pages/master_data/listSubMenu')
    },
    {
        path: '/master-data/countries',
        name: 'Countries',
        meta: { authRequired: true },
        component: () => import('../views/pages/master_data/countries')
    },
    {
        path: '/master-data/colors',
        name: 'Colors',
        meta: { authRequired: true },
        component: () => import('../views/pages/master_data/color')
    },
    {
        path: '/master-data/sizes',
        name: 'Sizes',
        meta: { authRequired: true },
        component: () => import('../views/pages/master_data/sizes')
    },
    {
        path: '/master-data/specifications',
        name: 'Specifications',
        meta: { authRequired: true },
        component: () => import('../views/pages/master_data/specifications')
    },
    {
        path: '/master-data/filters',
        name: 'Filters',
        meta: { authRequired: true },
        component: () => import('../views/pages/master_data/filters')
    },
    {
        path: '/master-data/product-specification-filter',
        name: 'ProductSpecificationFilterList',
        meta: { authRequired: true },
        component: () => import('../views/pages/master_data/productSpecificationFilters/index')
    },
    {
        path: '/master-data/product-specification-filter/add',
        name: 'ProductSpecificationFilterAdd',
        meta: { authRequired: true },
        component: () => import('../views/pages/master_data/productSpecificationFilters/add')
    },
    {
        path: '/master-data/product-specification-filter/edit',
        name: 'ProductSpecificationFilterEdit',
        meta: { authRequired: true },
        component: () => import('../views/pages/master_data/productSpecificationFilters/edit')
    },
    {
        path: '/master-data/home-sliders',
        name: 'HomeSliders',
        meta: { authRequired: true },
        component: () => import('../views/pages/master_data/homeSliders')
    },
    {
        path: '/master-data/shop-by-rooms',
        name: 'ShopByRooms',
        meta: { authRequired: true },
        component: () => import('../views/pages/master_data/shopByRooms')
    },

    // ***********  Master Data End  *********** //

    // ***********  Coupons Start  *********** //
    {
        path: '/coupons',
        name: 'Coupons',
        meta: { authRequired: true },
        component: () => import('../views/pages/coupons/index')
    },
    {
        path: '/import-products',
        name: 'ImportProducts',
        meta: { authRequired: true },
        component: () => import('../views/pages/products/ImportProduct')
    },
    // ***********  Coupons End  *********** //

    // ***********  Products Start  *********** //
    {
        path: '/products',
        name: 'Products',
        meta: { authRequired: true },
        component: () => import('../views/pages/products/productList')
    },
    {
        path: '/product-add',
        name: 'ProductAdd',
        meta: { authRequired: true },
        component: () => import('../views/pages/products/addProduct')
    },
    {
        path: '/product-edit',
        name: 'ProductEdit',
        meta: { authRequired: true },
        component: () => import('../views/pages/products/editProduct')
    },
    {
        path: '/product-details',
        name: 'ProductDetails',
        meta: { authRequired: true },
        component: () => import('../views/pages/products/productDetails')
    },
    // ***********  Products End  *********** //

    // ***********  Term and Condition Start  *********** //
    {
        path: '/termAndCondition',
        name: 'TermAndCondition',
        meta: { authRequired: true },
        component: () => import('../views/pages/cms/term_and_condition/index')
    },
    // ***********  Term and Condition End  *********** //

    // ***********  Privacy Policy Start  *********** //
    {
        path: '/privacyPolicy',
        name: 'PrivacyPolicy',
        meta: { authRequired: true },
        component: () => import('../views/pages/cms/privacy_policy/index')
    },

    // ***********  Privacy Policy End  *********** //

    // ***********  Cookies Policy Start  *********** //
    {
        path: '/cookiesPolicy',
        name: 'CookiesPolicy',
        meta: { authRequired: true },
        component: () => import('../views/pages/cms/cookies_policy/index')
    },
    // ***********  Cookies Policy End  *********** //

    // ***********  Open Order Start  *********** //
    {
        path: '/open-order/order-list',
        name: 'OpenOrderList',
        meta: { authRequired: true },
        component: () => import('../views/pages/orders/open_orders/order_list')
    },
    {
        path: '/cancel-order/cancel-order-list',
        name: 'CancelOrderList',
        meta: { authRequired: true },
        component: () => import('../views/pages/orders/open_orders/cancelOrderList')
    },
    {
        path: '/open-order/order-details',
        name: 'OpenOrderDetail',
        meta: { authRequired: true },
        component: () => import('../views/pages/orders/open_orders/order_details')
    },
    {
        path: '/cancel-order/cancel-order-details',
        name: 'CancelOrderDetail',
        meta: { authRequired: true },
        component: () => import('../views/pages/orders/open_orders/cancelOrderDetails')
    },
    // ***********  Open Order End  *********** //

    // ***********  Collections Start  *********** //
    {
        path: '/collections',
        name: 'CollectionList',
        meta: { authRequired: true },
        component: () => import('../views/pages/products/collections/collections')
    },
    // ***********  Collections End  *********** //

    // ***********  Collection Products Start  *********** //
    {
        path: '/collection-products',
        name: 'CollectionProduct',
        meta: { authRequired: true },
        component: () => import('../views/pages/products/productCollections/index')
    },
    {
        path: '/collection-product-details',
        name: 'CollectionProductDetails',
        meta: { authRequired: true },
        component: () => import('../views/pages/products/productCollections/view')
    },
    {
        path: '/collection-products-add',
        name: 'CollectionProductAdd',
        meta: { authRequired: true },
        component: () => import('../views/pages/products/productCollections/addProductCollection')
    },
    // ***********  Collection Products End  *********** //


    // ***********  Change Password start  *********** //
    {
        path: '/change-password',
        name: 'ChangePassword',
        meta: { authRequired: true },
        component: () => import('../views/pages/account/change-password')
    },
    // ***********  Change Password End  *********** //

    // ***********  Users Start  *********** //
    {
        path: '/users',
        name: 'User',
        meta: { authRequired: true },
        component: () => import('../views/pages/users/index')
    },
    {
        path: '/user-details',
        name: 'UserDetails',
        meta: { authRequired: true },
        component: () => import('../views/pages/users/details')
    },
    // ***********  Users End  *********** //

    // ***********  Section Start  *********** //
    {
        path: '/sections',
        name: 'Section',
        meta: { authRequired: true },
        component: () => import('../views/pages/sections/index')
    },

    // ***********  Section End  *********** //

    // ***********  Page Start  *********** //
    {
        path: '/pages',
        name: 'Page',
        meta: { authRequired: true },
        component: () => import('../views/pages/pages/index')
    },

    {
        path: '/user-payments',
        name: 'Payment',
        meta: { authRequired: true },
        component: () => import('../views/pages/payment/index')
    },

    // ***********  Page End  *********** //

    // ***********  Role Management Start  *********** //
    {
        path: '/roles',
        name: 'Roles',
        meta: { authRequired: true },
        component: () => import('../views/pages/role_management/index')
    },
    {
        path: '/add-role',
        name: 'AddRoles',
        meta: { authRequired: true },
        component: () => import('../views/pages/role_management/add')
    },

    {
        path: '/edit-role',
        name: 'EditRoles',
        meta: { authRequired: true },
        component: () => import('../views/pages/role_management/edit')
    },

    // ***********  Role Management End  *********** //

    // ***********  User Management Start  *********** //
    {
        path: '/user-management',
        name: 'UserManagement',
        meta: { authRequired: true },
        component: () => import('../views/pages/user_management/index')
    },

    // ***********  User Management End  *********** //

    // ***********  Admin Section Start  *********** //
    {
        path: '/admin-sections',
        name: 'AdminSection',
        meta: { authRequired: true },
        component: () => import('../views/pages/admin_section/index')
    },

    // ***********  Admin Section End  *********** //


    // ***********  Admin Page Start  *********** //
    {
        path: '/admin-page',
        name: 'AdminPage',
        meta: { authRequired: true },
        component: () => import('../views/pages/admin_pages/index')
    },

    // ***********  Admin Page End  *********** //

    {
        path: '/activity-logs',
        name: 'ActivityLogs',
        meta: { authRequired: true },
        component: () => import('../views/pages/logs/index')
    },

    {
        path: '/404',
        name: 'FileNotFount',
        meta: { authRequired: true },
        component: () => import('../views/pages/utility/error-404')
    },

]
